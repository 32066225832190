<template>
   <section class="cont marketing-members-cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>全员营销</el-breadcrumb-item>
            <el-breadcrumb-item>营销会员</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 查询框 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>营销人员信息：</label>
                  <el-select v-model="conditionType" class="width-122 m-right-10" :placeholder="$t('msg.select')">
                     <el-option
                           v-for="item in conditionTypeOpt"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                     </el-option>
                  </el-select>
                  <el-input v-model="condition" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label>营销人员状态：</label>
                  <el-select v-model="state" :placeholder="$t('msg.select')" clearable>
                     <el-option
                           v-for="item in stateOpt"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>营销分组：</label>
                  <el-select v-model="groupId" :placeholder="$t('msg.select')" clearable>
                     <el-option
                           v-for="item in groupOpt"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
               <el-button type="primary" @click="handleQuery(true)">搜索</el-button>
               <el-button @click="handleQuery(false)">重置</el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <!-- 表格 -->
            <el-table
                  ref="mainTable" v-loading="tableLoad" border fit
                  :data="tableData" :stripe="true">
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column label="会员信息" width="360">
                  <template slot-scope="scope" v-if="scope.row.memberDTO">
                     <el-row class="d-flex">
                        <img :src="scope.row.memberDTO.headPortrait" alt="">
                        <el-row class="m-left-10">
                           <p>会员编号：{{ scope.row.memberDTO.id }}</p>
                           <p>会员昵称：{{ scope.row.memberDTO.nickName }}</p>
                           <p>手机号码：{{ scope.row.memberDTO.mobile }}</p>
                           <p>营销分组：{{ scope.row.groupName }}</p>
                        </el-row>
                     </el-row>
                  </template>
               </el-table-column>
               <el-table-column prop="memberDTO.invitationCode" label="邀请码" width="110"></el-table-column>
               <el-table-column prop="parentId" label="上级推荐人" width="110"></el-table-column>
               <el-table-column label="营销人员状态" width="130">
                  <template slot-scope="scope">
                     {{ scope.row.state | filterFrozenState(that) }}
                  </template>
               </el-table-column>
               <el-table-column label="推广会员数" width="110">
                  <template slot-scope="scope">
                     {{ scope.row.level1Num + scope.row.level2Num }}
                  </template>
               </el-table-column>
               <el-table-column prop="orderNum" label="营销订单数" width="110"></el-table-column>
               <el-table-column prop="orderAmount" label="营销订单金额" width="130"></el-table-column>
               <el-table-column prop="commission" label="营销佣金" width="90"></el-table-column>
               <el-table-column :label="$t('msg.operating')" fixed="right">
                  <template slot-scope="scope">
                     <el-button  type="text" @click="getDistributionData(scope.row)">
                        <el-link type="primary">分销资料</el-link>
                     </el-button>
                     <el-button type="text" @click="promotionMembers(scope.row.userId)">
                        <el-link type="primary">推广会员</el-link>
                     </el-button>
                     <el-button type="text" @click="incomeDetail(scope.row.userId)">
                        <el-link type="danger">收入明细</el-link>
                     </el-button>
                     <el-button type="text" @click="visibleParent = true; id = scope.row.id, parentId = scope.row.parentId">
                        <el-link type="danger">修改上级</el-link>
                     </el-button>
                     <el-button type="text" @click="changeFrozenState(scope.row.id, scope.row.state, scope.row.memberDTO)">
                        <el-link type="danger">{{ scope.row.state === 'NO' ? '冻结' : '解冻' }}资格</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </el-row>
         <!-- 推广会员 -->
         <el-dialog title="推广会员" :visible.sync="visiblePromotion" :before-close="closePromotion" width="1400px">
            <el-row class="content-box">
               <!-- 查询框 -->
               <!--         <el-row class="search-box">
                  <el-row class="search-row">
                     <el-row class="search-item">
                        <label>会员信息：</label>
                        <el-select v-model="conditionType" class="width-120 m-right-10" :placeholder="$t('msg.select')">
                           <el-option
                                 v-for="item in conditionTypeOpt"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value">
                           </el-option>
                        </el-select>
                        <el-input v-model="promotionCondition"></el-input>
                     </el-row>
                     <el-button type="primary" @click="handleQuery(true)">搜索</el-button>
                     <el-button @click="handleQuery(false)">重置</el-button>
                  </el-row>
               </el-row>-->
               <!-- 主体内容 -->
               <el-row class="table-box">
                  <el-row class="table-head-btn">
                     <el-button-group>
                        <el-button :class="marketingLevel === '' ? 'bg-gradient' : ''" @click="changeFansLevel('')"><span>全部</span></el-button>
                        <el-button :class="marketingLevel === '1' ? 'bg-gradient' : ''" @click="changeFansLevel('1')"><span>直推客户</span></el-button>
                        <el-button :class="marketingLevel === '2' ? 'bg-gradient' : ''" @click="changeFansLevel('2')"><span>间推客户</span></el-button>
                     </el-button-group>
                  </el-row>
                  <!-- 表格 -->
                  <el-table
                        ref="mainTable" border fit size="small"
                        :data="promotionData" :stripe="true">
                     <el-table-column label="序号" width="60">
                        <template slot-scope="scope">
                           {{scope.$index + 1}}
                        </template>
                     </el-table-column>
                     <el-table-column label="会员信息" min-width="180">
                        <template slot-scope="scope" v-if="scope.row.memberDTO">
                           <el-row class="d-flex">
                              <img width="80" :src="scope.row.memberDTO.headPortrait" alt="">
                              <el-row class="m-left-10">
                                 <p>会员编号：{{ scope.row.memberDTO.id }}</p>
                                 <p>会员昵称：{{ scope.row.memberDTO.nickName }}</p>
                                 <p>手机号码：{{ scope.row.memberDTO.mobile }}</p>
                                 <p>会员等级：{{ scope.row.memberDTO.level }}</p>
                                 <p>性别：{{ scope.row.memberDTO.gender | filterGender(that) }}</p>
                                 <p>注册时间：</p>
                              </el-row>
                           </el-row>
                        </template>
                     </el-table-column>
                     <el-table-column label="上级推荐人" min-width="130">
                        <template slot-scope="scope" v-if="scope.row.parentMemberDTO">
                           <p>会员编号：{{ scope.row.parentMemberDTO.id }}</p>
                           <p>会员昵称：{{ scope.row.parentMemberDTO.nickName }}</p>
                           <p>手机号码：{{ scope.row.parentMemberDTO.mobile }}</p>
                           <p>会员等级：{{ scope.row.parentMemberDTO.marketingLevel }}</p>
                        </template>
                     </el-table-column>
                     <el-table-column label="推广会员数">
                        <template slot-scope="scope">
                           {{ scope.row.level1Num + scope.row.level2Num }}
                        </template>
                     </el-table-column>
                     <el-table-column prop="orderNum" label="营销订单数"></el-table-column>
                     <el-table-column prop="orderAmount" label="营销订单金额"></el-table-column>
                     <el-table-column prop="commission" label="营销佣金"></el-table-column>
                     <el-table-column prop="integral" label="营销积分"></el-table-column>
                  </el-table>
                  <!-- 分页 -->
                  <el-pagination small layout="prev, pager, next" @current-change="changePromotionPage" :page-size="promotionLimit" :total="promotionTotal"></el-pagination>
               </el-row>
            </el-row>
         </el-dialog>
         <!-- 收入明细 -->
         <el-dialog title="收入明细" :visible.sync="visibleIncome" width="1200px">
            <el-row class="content-box">
               <!-- 查询框 -->
               <el-row class="search-box">
                  <el-row class="search-row">
                     <el-row class="search-item">
                        <label>记录时间：</label>
                        <date-packer class="width-260" :datePacker="dateValue" @setDatePacker="getDatePacker"></date-packer>
                     </el-row>
                     <el-button type="primary" @click="queryIncome(true)">搜索</el-button>
                     <el-button @click="queryIncome(false)">重置</el-button>
                  </el-row>
               </el-row>
               <!-- 主体内容 -->
               <el-row class="table-box">
                  <!-- 表格 -->
                  <el-table
                        ref="mainTable" size="small" border fit
                        :data="incomeData" :stripe="true">
                     <el-table-column label="序号" width="60">
                        <template slot-scope="scope">
                           {{scope.$index + 1}}
                        </template>
                     </el-table-column>
                     <el-table-column prop="orderNo" label="记录编号" min-width="130"></el-table-column>
                     <el-table-column prop="orderNo" label="来源订单" min-width="130"></el-table-column>
                     <el-table-column prop="amount" label="收入金额"></el-table-column>
                     <el-table-column prop="integral" label="收入积分"></el-table-column>
                     <el-table-column prop="type" label="收入类型"></el-table-column>
                     <el-table-column prop="createTime" label="记录日期" min-width="100"></el-table-column>
                     <el-table-column label="到账状态">
                        <template slot-scope="scope">
                           {{ scope.row.state | filterState(that) }}
                        </template>
                     </el-table-column>
                  </el-table>
                  <!-- 分页 -->
                  <el-pagination small layout="prev, pager, next" @current-change="changeIncomePage" :current-page.sync="incomePage" :page-size="incomeLimit" :total="incomeTotal"></el-pagination>
               </el-row>
            </el-row>
         </el-dialog>
         <!-- 人员资料 -->
         <el-dialog class="detail-dialog-box" title="营销人员资料" :visible.sync="visibleData" width="660px">
            <el-row class="title t-a-left">
               <h6>基本信息</h6>
            </el-row>
            <el-row class="text-list">
               <el-row>
                  <el-col :span="12">
                     <label class="width-100">会员编号：</label>
                     <span>{{ distributionData.memberDTO.id }}</span>
                  </el-col>
                  <el-col :span="12">
                     <label class="width-100">会员昵称：</label>
                     <span>{{ distributionData.memberDTO.nickName }}</span>
                  </el-col>
               </el-row>
               <el-row>
                  <el-col :span="12">
                     <label class="width-100">手机号码：</label>
                     <span>{{ distributionData.memberDTO.mobile }}</span>
                  </el-col>
                  <el-col :span="12">
                     <label class="width-100">间推会员数：</label>
                     <span>{{ distributionData.level2Num }}</span>
                  </el-col>
               </el-row>
               <el-row>
                  <el-col :span="12">
                     <label class="width-100">邀请码：</label>
                     <span>{{ distributionData.memberDTO.invitationCode }}</span>
                  </el-col>
                  <el-col :span="12">
                     <label class="width-100">上级推荐人编号：</label>
                     <span>{{ distributionData.parentId }}</span>
                  </el-col>
               </el-row>
               <el-row>
                  <el-col :span="12">
                     <label class="width-100">直推会员数：</label>
                     <span>{{ distributionData.level1Num }}</span>
                  </el-col>
                  <el-col :span="12">
                     <label class="width-100">营销分组：</label>
                     <span>{{ distributionData.groupName }}</span>
                  </el-col>
               </el-row>
            </el-row>
            <el-row class="title m-top-50 t-a-left">
               <h6>分销信息</h6>
            </el-row>
            <el-row class="text-list">
               <el-row>
                  <el-col :span="12">
                     <label class="width-100">营销订单数：</label>
                     <span>{{ distributionData.orderNum }}</span>
                  </el-col>
                  <el-col :span="12">
                     <label class="width-100">营销订单金额：</label>
                     <span>{{ distributionData.orderAmount }}</span>
                  </el-col>
               </el-row>
               <el-row>
                  <el-col :span="12">
                     <label class="width-100">营销所得佣金数：</label>
                     <span>{{ distributionData.commission }}</span>
                  </el-col>
                  <el-col :span="12">
                     <label class="width-100">佣金发放总金额：</label>
                     <span>{{ distributionData.commissionGrant }}</span>
                  </el-col>
               </el-row>
               <el-row>
                  <el-col :span="12">
                     <label class="width-100">未发放佣金金额：</label>
                     <span>{{ computeUnissued }}</span>
                  </el-col>
                  <el-col :span="12">
                     <label class="width-100">营销所得积分数：</label>
                     <span>{{ distributionData.integral }}</span>
                  </el-col>
               </el-row>
            </el-row>
         </el-dialog>
         <!-- 修改上级 -->
         <el-dialog title="修改上级" :visible.sync="visibleParent" width="550px" :close-on-click-modal="false">
            <el-form class="form-dialog-box" :model="ruleForm" :rules="rules" ref="ruleForm">
               <el-form-item label="当前上级">{{ parentId }}</el-form-item>
               <el-form-item label="上级推荐人编号" prop="parentId">
                  <el-input v-model="ruleForm.parentId" placeholder="上级推荐人编号"></el-input>
               </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
               <el-button @click="visibleParent = false">取 消</el-button>
               <el-button type="primary" @click="updateParent">确 定</el-button>
            </span>
         </el-dialog>
      </el-row>
   </section>
</template>

<script>
import { mapState } from 'vuex'
import { urlObj , smtech } from '@/api/interface'
import { marketing } from '@/api/interface/smtech'
import { getDict } from '@/common/js/common'
export default {
   data(){
      return{
         that: this,
         tableData: [],         // 表格用户列表
         tableLoad: true,      // 表格加载效果
         limit: 1,              // 每页数
         page: 1,               // 当前页
         total: 0,              // 用户列表总条目数
         action: 'add',         // 当前操作类型
         visibleData: false,    // 人员资料
         visibleParent: false,
         conditionType: 'userId',
         groupId: '',           // 营销分组ID
         groupOpt: [],          // 营销分组列表
         conditionTypeOpt: [
            { label: '会员编号', value: 'userId' },
            { label: '会员昵称', value: 'nickName' },
            { label: '会员姓名', value: 'realName' },
            { label: '手机号码', value: 'mobile' }
         ],
         stateOpt: [
            { label: '未冻结', value: 'NO' },
            { label: '已冻结', value: 'YES' }
         ],
         condition: '',           // 查询条件
         state: '',               // 营销人员状态
         id: '',                  // 当前营销会员ID
         parentId: '',            // 当前营销会员上级
         ruleForm: {
            parentId: '',         // 上级推荐人编号
         },
         rules: {
            parentId: [
               { required: true, message: '请输入上级推荐人编号', trigger: 'blur' }
            ]
         },
         /* 推广会员 */
         visiblePromotion: false,
         promotionData: [],       // 推广会员列表
         promotionLimit: 7,       // 推广会员每页数
         promotionPage: 1,        // 推广会员当前页
         promotionTotal: 0,       // 推广会员总条数
         promotionCondition: '',  // 查询条件
         marketingLevel: '',      // 粉丝层级
         /* 收入明细 */
         visibleIncome: false,    // 收入明细弹窗
         dateValue: [],           // 收入明细时间段
         incomeData: [],          // 收入明细列表
         incomeTotal: 0,          // 收入总条数
         incomePage: 1,
         incomeLimit: 7,
         /* 分销资料 */
         distributionData: {
            memberDTO: {}
         },
         // 提示文本
         del_success: "删除成功！",
         add_success: "添加成功！",
         update_success: '修改成功！',
         userName_no_null: '用户名不能为空！',
         mobile_no_null: '手机号不能为空！',
         nickName_no_null: '昵称不能为空！',
         confirm: '确定',
         cancel: '取消',
         confirm_remove: '确定移除？',
         prompt: '提示！'
      }
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData']),
      computeUnissued() {
         return (this.distributionData.commissionGrant - this.distributionData.commission).toFixed(2)
      }
   },
   mounted() {
      getDict(['gender', 'payment-state', 'frozen-state'])
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getMarketingMembers()
      this.getMarketingGroups()
   },
   methods: {
      // 获取会员营销列表
      getMarketingMembers(){
         const url = marketing.marketingMembers + `?limit=${this.limit}&page=${this.page}`
         let param = { hotelId: this.hotelInfo.id, state: this.state, groupId: this.groupId, companyId: this.hotelInfo.storeId }
         if (this.conditionType === 'userId') {
            param.userId = this.condition
         }else {
            param['memberDTO.' + this.conditionType] = this.condition
         }
         this.$axios.post(url, param).then(res => {
            if(res.success){
               this.tableLoad = false
               this.total = res.total
               this.tableData = res.records
            }
         })
      },
      // 获取营销分组
      getMarketingGroups() {
         const url = marketing.marketingGroups
         const param = { hotelId: this.hotelInfo.id }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               let groupOpt = res.records
               if (groupOpt.length === 0) return this.groupOpt.length = 0
               this.groupOpt = groupOpt.map(item => ({label: item.groupName, value: item.id}) )
            }
         })
      },
      // 搜索会员营销
      handleQuery(bool){
         this.page = 1
         if (bool) return this.getMarketingMembers()
         this.conditionType = 'userId'
         this.condition = ''
         this.state = ''
         this.groupId = ''
         this.getMarketingMembers()
      },
      // 改变每页数
      pageChange(num){
         this.limit = num
         this.getMarketingMembers()
      },
      // 改变当前页
      handlePaging(num){
         this.page = num
         this.getMarketingMembers()
      },
      // 分销资料
      getDistributionData(data){
         this.visibleData = true
         this.distributionData = data
         if (!data.memberDTO) this.distributionData.memberDTO = {}
      },
      // 推广会员
      promotionMembers(userId) {
         this.userId = userId
         this.visiblePromotion = true
         this.getPromotionMembers()
      },
      // 获取推广会员列表
      getPromotionMembers(){
         const url = marketing.promotionMembers + `?limit=${this.promotionLimit}&page=${this.promotionPage}`
         let param = { hotelId: this.hotelInfo.id, marketingLevel: this.marketingLevel, userId: this.userId,companyId: this.hotelInfo.storeId }
         this.$axios.post(url, param).then(res => {
            if (res.success) {
               this.promotionTotal = res.total
               this.promotionData = res.records
            }
         })
      },
      // 改变粉丝层级
      changeFansLevel(val){
         this.marketingLevel = val
         this.getPromotionMembers()
      },
      // 改变推广会员当前页
      changePromotionPage(num){
         this.promotionPage = num
         this.getPromotionMembers()
      },
      // 关闭推广会员弹窗
      closePromotion() {
         this.visiblePromotion = false
         this.marketingLevel = ''
      },
      // 修改上级
      updateParent() {
         this.$refs.ruleForm.validate(valid => {
            if (!valid) return
            const url = marketing.updateParentId
            const param = { id: this.id, hotelId: this.hotelInfo.id, parentId: this.ruleForm.parentId }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     type: 'success',
                     message: '修改上级推荐人成功！'
                  });
                  this.visibleParent = false
                  this.getMarketingMembers()
               }
            })
         })
      },
      // 获取收入明细时间段
      getDatePacker(val) {
         this.dateValue = val
      },
      // 查询收入明细
      queryIncome(bool) {
         this.incomePage = 1
         if (bool) return this.getIncomeDetail()
         this.dateValue = []
         this.getIncomeDetail()
      },
      // 收入明细
      incomeDetail(userId){
         this.userId = userId
         this.visibleIncome = true
         this.incomePage = 1
         this.getIncomeDetail()
      },
      // 获取营销收入明细列表
      getIncomeDetail(){
         const url = marketing.marketingUserIncome + `?limit=${this.incomeLimit}&page=${this.incomePage}`
         const param = {
            userId: this.userId,
            hotelId: this.hotelInfo.id,
            beginTime: this.dateValue[0],
            endTime: this.dateValue[1]
         }
         this.$axios.post(url, param).then((res) => {
            if(res.success){
               this.incomeTotal = res.total
               this.incomeData = res.records
            }
         })
      },
      // 改变当前页
      changeIncomePage(num){
         this.incomePage = num
         this.getIncomeDetail()
      },
      // 改变会员冻结状态
      changeFrozenState(id, state, memberDTO = {}) {
         this.$confirm(`确定${ state === 'YES' ? '解冻' : '冻结' }分销会员：${memberDTO.nickName || ''}`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            let param = { id, state: state === 'YES' ? 'NO' : 'YES' }
            let url = marketing.updateFrozenStatus;
            this.$axios.post(url, param).then(() => {
               this.$message({
                  showClose: true,
                  message: `${ state === 'YES' ? '解冻' : '冻结' }成功！`,
                  type: 'success'
               })
               this.getMarketingMembers()
            })
         }).catch(() => {
            this.$message({
               type: 'info',
               message: '取消操作'
            });
         });
      }
   },
   filters: {
      filterGender(val, that) {
         if (val && that.dictData['gender']) return that.dictData['gender'][val] || val
      },
      filterState(val, that) {
         if (val && that.dictData['payment-state']) return that.dictData['payment-state'][val] || val
      },
      filterFrozenState(val, that) {
         if (val && that.dictData['frozen-state']) return that.dictData['frozen-state'][val] || val
      }
   },
   watch: {
      hotelInfo(newVal, oldVal) {
         if (newVal.id !== oldVal.id && oldVal) {
            this.getMarketingMembers()
            this.getMarketingGroups()
         }
      }
   }
}
</script>

<style lang="scss" scoped>
.marketing-members-cont{
   .content-box {
      .content-box { padding: 20px 40px }
   }
   .form-dialog-box{
      .el-form-item{ width: 100% }
   }
}
</style>
